<template>
  <!-- <div> <iframe :src="PDFData" frameborder="0" style="width: 100%; height: 100vh"></iframe></div> -->
  <div class='ukeyGuide'>
    <pdfPreview :url='PDFData'></pdfPreview>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      PDFData:
        'https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/downloadManage/%E8%B1%B8%E4%BF%A1CA%E6%95%B0%E5%AD%97%E8%AF%81%E4%B9%A6%E5%8A%A9%E6%89%8B%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf'
    }
  },
  created() {
    this.name =1
  }
}
</script>

<style>
body,
html {
  min-width: 100px;
  width: 100%;
}
.ukeyGuide {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
